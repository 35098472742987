<template>
  <div class="content-sidebar">
    <div class="content-sidebar-body">
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ trunk.trunk_name }}</div>
      <div class="titles">{{ __("Type") }}</div>
      <div class="details">{{ trunk.registration_type }}</div>
      <div class="titles">{{ __("SIP Domain") }}</div>
      <div class="details">{{ trunk.sip_domain }}</div>
      <div v-show="trunk.primary_registrant.register_user_name !== ''">
        <div class="titles">{{ __("Primary Register Username") }}</div>
        <div class="details">
          {{ trunk.primary_registrant.register_user_name }}
        </div>
        <div class="titles">{{ __("Outbound proxy") }}</div>
        <div class="details">
          {{ trunk.primary_registrant.outbound_proxy_ip_port }}
        </div>
        <div class="titles">{{ __("Registration Status") }}</div>
        <div class="details">
          {{ trunk.primary_registrant.registration_status }}
        </div>
      </div>
      <div v-show="trunk.secondary_registrant.register_user_name !== ''">
        <div class="titles">{{ __("Secondary Register Username") }}</div>
        <div class="details">
          {{ trunk.secondary_registrant.register_user_name }}
        </div>
        <div class="titles">{{ __("Outbound proxy") }}</div>
        <div class="details">
          {{ trunk.secondary_registrant.outbound_proxy_ip_port }}
        </div>
        <div class="titles">{{ __("Registration Status") }}</div>
        <div class="details">
          {{ trunk.secondary_registrant.registration_status }}
        </div>
      </div>
    </div>
    <div class="details">
      <el-button
        class="editBtn"
        @click="$emit('click')"
        :disabled="!this.canWrite(additionalValidateRoute)"
        >{{ __("Edit") }}</el-button
      >
      <el-button
        :disabled="!this.canWrite(additionalValidateRoute)"
        class="deleteBtn"
        @click="$emit('dltClick')"
        >{{ __("Delete") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "TrunkInfo",
  components: {},
  props: {
    trunk: {
      type: Object,
      required: false,
      default: null
    },
    additionalValidateRoute: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .content-sidebar {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
::v-deep .content-sidebar-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

::v-deep .content-sidebar-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // todo to update/remove after getting rid of global content creator from outside canvas
  padding-top: 10px;
  margin-bottom: 50px;
}

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 20px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}
</style>
